<template>
  <svg
    width="17"
    height="34"
    viewBox="0 0 17 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 18.4167L0 34L7.08333 19.8333L0 15.5833L17 0L9.91667 14.1667L17 18.4167Z"
      fill="#166CFB"
    />
  </svg>
</template>

<script>
export default {
  name: "IconFlash",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
