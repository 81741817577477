import axios from 'axios'

export default function (Vue, router, store) {
  // Axios
  axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL + '/api'
  axios.defaults.withCredentials = true;

  // Auth
  const uriParams = new URLSearchParams(window.location.search);
  const authToken = uriParams.get('token');

  if (authToken) {
    store.commit('user/SET_TOKEN', authToken);
  }
  // Axios interceptors
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 500) {
        console.log('[response server error]', error.response.data.message);
      }
      const allowed = ['/', '/password-reset', '/signup', '/signin', '/privacy', '/test'];
      if (error && error.response) {
        if (error.response.status === 401 && !allowed.includes(window.location.pathname)) {
          // Vue.prototype.$toaster.error('401. Требуется авторизация');
          console.log('401. Требуется авторизация')
          // store.dispatch('user/logout')
        }
        if (error.response.status === 400) {
          // Vue.prototype.$toaster.error(error.response.data.message);
          console.log('400. Сервер не смог обработать запрос')
          // store.dispatch('user/logout')
        }
        if (error.response.status === 500) {
          // Vue.prototype.$toaster.error('500. Что-то пошло не так...');
          console.log('500. Что-то пошло не так...')
          // store.dispatch('user/logout')
        }
        if (error.response.status === 403) {
          // Vue.prototype.$toaster.error('403. Доступ запрещен!');
          console.log('403. Доступ запрещен!')
          // store.dispatch('user/logout')
        }
        if (error.response.status === 404) {
          // Vue.prototype.$toaster.error('404. Не найдено');
          console.log('404. Не найдено')
          // store.dispatch('user/logout')
        }
      }

      throw error;
    },
  );
}
