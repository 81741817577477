import '@/setup/installCompositionApi.js'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import auth from '@/setup/auth.js'
import helpers from '@/setup/helpers.js'
import libs from '@/setup/libs.js'
import '@/css/app.scss'
import '@/assets/styles.postcss'
import AOS from "aos";
import "aos/dist/aos.css";

Vue.config.productionTip = false;

// Setup stuff
auth(Vue, router, store);
helpers(Vue, router, store);
libs(Vue, router, store);

new Vue({
  created() {
    AOS.init({
      duration: 1000
    });
  },
  store,
  router,
  render: h => h(App)
}).$mount('#app')
