
export default function (Vue) {
  Vue.prototype.$wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  Vue.prototype.$log = function (...args) {
    console.log(`[${this.$options.name}] `, ...args)
  }
  // randoms
  Vue.prototype.$randomNumber = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  let colors = {}
  Vue.prototype.$randomColor = function (id, opacity) {
    const r = () => Math.floor(256 * Math.random())
    if (opacity) {
      return colors[id] || (colors[id] = `rgb(${r()}, ${r()}, ${r()}, ${opacity})`)
    } else {
      return colors[id] || (colors[id] = `rgb(${r()}, ${r()}, ${r()})`)
    }
  }

  Vue.prototype.$preloadImage = function (imagePath) {
    const img = new Image();
    return new Promise((resolve) => {
      img.src = imagePath;
      img.onload = () => {
        resolve(true);
      }
    })
  }
}
