import Vue from 'vue';
import VueRouter from 'vue-router';
import useGameState from "@/composables/useGameState";

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import(/* webpackChunkName: "test" */ '@/components//LandingPage/LandingQuestion.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('user/fetchUser');
      if (store.getters['user/isLogined']) {
        next({ name: 'CJM' })
      } else {
        next();
      }
    }
  },
  {
    path: '/about-cjm',
    name: 'AboutCJM',
    component: () => import(/* webpackChunkName: "test" */ '@/pages/LandingPage.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('user/fetchUser');
      if (store.getters['user/isLogined']) {
        next({ name: 'CJM' })
      } else {
        next();
      }
    }
  },
  {
    path: '/results',
    name: 'Results',
    component: () => import(/* webpackChunkName: "test" */ '@/pages/Results.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('user/fetchUser');
      if (store.getters['user/isLogined']) {
        const { stageData, updateGameState } = useGameState();
        updateGameState().then(() => {
          if (stageData.value.answered_stage_questions < stageData.value.total_stage_questions) {
            next({ name: 'CJM' })
          }
        })
        next();
      } else {
        next({ name: 'LandingPage' })
      }
    }
  },
  {
    path: '/cjm',
    name: 'CJM',
    component: () => import(/* webpackChunkName: "cjm" */ '@/pages/CjmPage.vue'),
    beforeEnter: async (to, from, next) => {
      await store.dispatch('user/fetchUser');
      if (store.getters['user/isLogined']) {
        next();
      } else {
        next({ name: 'LandingPage' })
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
