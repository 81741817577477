import axios from 'axios';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    currentUser: null,
    authToken: null,
  },
  mutations: {
    SET_USER: (state, payload) => {
      state.currentUser = payload;
    },
    SET_TOKEN: (state, payload) => {
      state.authToken = payload;
      axios.defaults.headers.common.Authorization = `Bearer ${payload}`;
    },
  },
  getters: {
    isLogined: (state) => state.currentUser && state.currentUser.id,
  },
  actions: {
    login() {
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/oauth/redirect`;
    },
    logout({ state, commit }) {
      // logout вызывается без префикса /api, дефолтный baseURL не переопределяется, создаю новый инстанс
      const instance = axios.create({
        baseURL: process.env.VUE_APP_SERVER_URL,
        headers: {Authorization: `Bearer ${state.authToken}`}
      });
      try {
        console.log('[vuex-user] logout start')
        instance.post('/logout')
      }
      catch (e) {
        console.log('[vuex-user] logout error', e)
      }
      finally {
        commit('SET_USER', {})
        commit('SET_TOKEN', null)
        localStorage.clear()
        console.log('[vuex-user] logout done')
        router.replace('/')
      }
    },
    async fetchUser({ commit, state }) {
      try {
        if (state.authToken) {
          commit('SET_TOKEN', state.authToken);
        }
        const { data } = await axios.get('/users/current');
        commit('SET_USER', data.data);
      } catch (e) {
        commit('SET_USER', null);
      }
    },
  },
};
