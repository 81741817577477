
<style lang="scss">
.back-modal {
  background: white;
  border-radius: 8px;
  color: black;

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @apply py-36px;
  }

  &__title {
    @apply text-center font-vtb-demibold text-24 leading-28 mt-44px;
  }

  &__desc {
    @apply text-center font-vtb-regular text-20 leading-28 mt-24px;
  }
}
</style>

<template>
  <modal :name="modalName" classes="back-modal" width="380" height="408">
    <div class="back-modal__container">
      <icon-flash />
      <div class="back-modal__title">Хочешь выйти в меню?</div>
      <div class="back-modal__desc">
        Весь прогресс будет потерян и придется спринт с начала
      </div>
      <div class="flex flex-col mt-48px">
        <button class="g-vtb-btn" @click="hide">Остаться</button>
        <button
          class="mt-28px text-base leading-20 text-center font-vtb-regular"
          @click="exit"
        >
          Выйти в меню
        </button>
      </div>
    </div>
    <button-modal-close class="back-modal__close" @click.native="hide" />
  </modal>
</template>

<script>
import ButtonModalClose from "./ButtonModalClose.vue";
import IconFlash from "./Icons/IconFlash.vue";

export default {
  name: "BackModal",
  components: {
    IconFlash,
    ButtonModalClose,
  },
  data() {
    return {
      modalName: "back-modal",
    };
  },
  methods: {
    hide() {
      this.$modal.hide(this.modalName);
    },
    exit() {
      this.hide();
      this.$router.push({ name: "Game" });
    },
  },
};
</script>
