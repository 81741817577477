import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import { fetchGame } from '../api/fetchGame';
import { startNewGame } from '../api/startNewGame';

const gameState = ref(null)
const dialogPoints = [0, 3, 7]
const showDialog = ref(true)
const state = reactive({
  isLoading: false,
  error: null,
});

export default function useGameState() {
  const updateGameState = async () => {
    showDialog.value = true
    state.isLoading = true
    try {
      const result = await fetchGame()
      gameState.value = result
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  }

  const restart = async () => {
    state.isLoading = true
    try {
      await startNewGame().then(() => {
        updateGameState()
      })
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  }

  const setShowDialog = () => {
    showDialog.value = false
  }

  const stageData = computed(() => {
    return gameState.value?.result?.stages.find(item => item.serial === 2)
  });

  const stage = computed(() => {
    if (stageData.value) {
      return stageData.value.answered_stage_questions;
    }
    return 0;
  });

  const shouldShowDialog = computed(() => {
    if (state.isLoading) {
      return false
    }
    return dialogPoints.includes(stage.value)
  })

  return {
    ...toRefs(state),
    stageData,
    updateGameState,
    restart,
    stage,
    showDialog,
    setShowDialog,
    shouldShowDialog,
  }
}