<style lang="postcss">
.version {
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 0, 0, 0.5);
  color: white;
  font-size: 10px;
  padding: 0px 4px;
}
#app > div.vue-notification-group > span > div {
  overflow: visible;
}
.cje-notification.vue-notification-template {
  @apply relative font-vtb-light;
  padding: 24px 44px 24px 136px;
  color: #ffffff;
  border-radius: 16px;
  margin-bottom: 60px;
  &:before {
    @apply absolute left-4 bg-contain bg-no-repeat bg-center;
    content: '';
    width: 100px;
    height: 100px;
    top: -28px;
  }

  & .notification-content {
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  &.success {
    background: #0d2c75;
    &:before {
      background-image: url('/images/post-01.svg');
    }
  }
  &.info {
  }
  &.error {
    background: #eb2d2d;
    &:before {
      background-image: url('/images/error-01.svg');
    }
  }
}
</style>

<template>
  <div id="app">
    <div class="version">
      <small>v{{ version }}</small>
    </div>
    <router-view />
    <BackModal />
    <notifications
      position="bottom center"
      classes="cje-notification"
      :width="notificationWidth"
    />
  </div>
</template>

<script>
import 'animate.css';

import '@/assets/fonts.scss';
import BackModal from '@/components/BackModal.vue';

export default {
  name: 'App',
  components: {
    BackModal,
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    notificationWidth() {
      return document.documentElement.clientWidth > 600 ? '458px' : '90%';
    },
  },
  mounted() {},
};
</script>
